import logo from './logo.svg';
import "./App.scss";
import { Td1Todolist } from './views/Td1-Todolist/Td1Todolist';

function App() {
  return (
    <div className="App">
     <Td1Todolist />
    </div>
  );
}

export default App;
