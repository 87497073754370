import React, { useCallback, useEffect, useState } from 'react'

export default function InputTodo(props) {
  const [newPasswordm, setNewPassword] = useState("");
  const [confirmPasswordm, setConfirmPassword] = useState("");

  const onChangePassword = useCallback((e)=>{
    setNewPassword(e.target.value);
  },[setNewPassword]) 

  const onConfirmChangePassword = useCallback((e)=>{
   setConfirmPassword(e.target.value)
  },[setConfirmPassword]) 
  return (
    <>
    <header>
      <h1>header</h1>
      <button value={'<'}></button>
    </header>
    <main className='main'>
      <div onSubmit={(e) => e.preventDefault()} className='px-3'>
        <input hidden type='text' autoComplete='username'></input>
        <div>
          <label className='form-label' htmlFor='pc1'>`poasword</label>
          <div>
            <input className='form-control form-control-password' type='password' aria-describedby='password' name='password' value={newPasswordm} onChange={onChangePassword} id='pc1' ></input>
            <button className='btn btn-password' type='button'></button>
          </div>
          <p className='form-text' id='password-helpText'>`aaaaaaaaaaaaaa</p>
          <p className='form-text' id='password-helpText'>`aaaaaaaaaaaaaa</p>
        </div>
        <div>
          <label className='form-label' htmlFor='pc2'>`poasword</label>
          <div>
            <input className='form-control form-control-password' type='password' aria-describedby='password' name='password' value={confirmPasswordm} onChange={onConfirmChangePassword} id='pc2'  ></input>
            <button className='btn btn-password' type='button'></button>
          </div>
          <p className='form-text' id='password-helpText'></p>
          <p className='form-text' id='password-helpText'></p>
        </div>
        <div>
        <button type='button'
          onClick={props.onclickAddTodo} disabled
        >add todo</button>
        </div>
      </div>
    </main>
    </>
  )
}
